import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import { Page } from "../../shared/Page/Page"
import { graphql, Link } from "gatsby"
import { SEO } from "../../utils/SEO"
import { GatsbyImage } from "gatsby-plugin-image"
import { FlatButton } from "../../shared/Ui/FlatButton/FlatButton"
import media from "css-in-js-media"

const Container = styled.div`
    background: #374151;
    box-shadow: #111827 -50rem 50rem -50px inset;

    .top-post {
        .right {
            perspective: 2000rem;
            transform-origin: center;
            transform-style: preserve-3d;

            .image-cont {
                border-radius: 20rem;
                overflow: hidden;
                transform-origin: center;
                box-shadow: rgb(129, 140, 248, 0.25) 0 48rem 100rem 0,
                    rgba(149, 157, 165, 0.2) 0 8rem 12rem;
                position: relative;

                //filter: saturate(0.1);
            }
        }

        .left {
            h2 {
                margin: 0 0 10rem !important;
            }
        }
    }

    .post {
        width: 500rem;
        display: flex;
        flex-direction: column-reverse;
        background: #fff;
        box-shadow: rgba(17, 12, 46, 0.15) 0 48rem 100rem 0;
        border-radius: 10rem;
        padding: 30rem 35rem;
        text-align: left;
        gap: 30rem;

        &.empty {
            background: transparent;
            border: 2rem dashed #4b5563;
            //box-shadow: none;
            min-height: 600rem;

            ${media("<tablet")} {
                display: none !important;
            }

            .description {
                color: #9ca3af !important;
                text-align: center !important;
                flex: 1;
                align-items: center;
                display: flex;
                justify-content: center;
            }
        }

        .left {
            flex: 1;
            display: flex;
            flex-direction: column;

            .sub {
                color: ${({ theme }) => theme.primary};
                font-family: "Gilroy-SemiBold", sans-serif;
            }

            h1 {
                margin: 15rem 0 10rem;
                font-family: "Gilroy-SemiBold", sans-serif;
                line-height: 40rem;
                color: ${({ theme }) => theme.text.dark};
            }

            h2 {
                margin: 0 0 auto;
                color: ${({ theme }) => theme.text.normal};
                font-size: 18rem;
                line-height: 32rem;
                font-weight: 400;
            }

            button {
                margin-top: 10rem;
            }

            .meta-info {
                display: flex;
                align-items: center;
                color: ${({ theme }) => theme.text.light};
                margin-bottom: 20rem;
                font-family: "Gilroy-Regular", sans-serif;
                flex-wrap: wrap;

                .avatar {
                    margin: 0 5rem;
                }
            }
        }

        .right {
            flex: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .gatsby-image-wrapper {
                //box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
            }
        }
    }

    .head {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        text-align: center;
        padding: 80rem 0 80rem;
        box-shadow: ${({ theme }) => theme.surfaces.dark} 0 -20rem 20rem -20px
            inset;
        background: linear-gradient(
            ${({ theme }) => theme.surfaces.lighter},
            #f8fafc
        );

        .content {
            width: 100%;
            justify-content: center;
            display: flex;
            max-width: 1000rem;

            .post {
                flex: 1;
                flex-direction: row-reverse;
                box-shadow: none;
                background: transparent;
                gap: 50rem;
                //transform: translateY(80rem);

                .left {
                    justify-content: center;
                }

                //max-width: unset;
            }
        }
    }

    .other-posts {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        padding: 100rem 50rem;
        gap: 50rem;
        flex-wrap: wrap;
        justify-content: space-evenly;

        //> div {
        //    flex: 1;
        //    width: 100%;
        //    display: flex;
        //    max-width: 900rem;
        //
        //    &:nth-of-type(event) {
        //        justify-content: flex-end;
        //    }
        //}

        .post {
            max-width: 350rem;

            .gatsby-image-wrapper {
                max-height: 200rem;
                border-radius: 10rem;
            }
        }
    }

    ${media("<desktop")} {
        .head {
            padding-top: 20rem;

            .content {
                .post {
                    flex-direction: column-reverse;
                    text-align: center;
                    max-width: 600rem;

                    .meta-info {
                        justify-content: center;
                        margin-bottom: 20rem;
                    }
                }
            }
        }
    }
`

const Blog = ({ location, data }) => {
    const isMounted = useRef(true)
    const animationFrameRef = useRef<number>()
    const animContainerRef = useRef<HTMLDivElement>(null)
    const valuesRef = useRef<{ x: number; y: number }>({ x: 0, y: 0 })

    useEffect(() => {
        const paint = () => {
            if (!isMounted.current) {
                return
            }

            const newX = Math.cos((valuesRef.current.x += 0.005)) * 8
            const newY = Math.cos((valuesRef.current.y += 0.003)) * 20

            animContainerRef.current.style.transform = `rotateX(${newX}deg) rotateY(${newY}deg)`

            animationFrameRef.current = requestAnimationFrame(paint)
        }

        if (animationFrameRef.current !== 0) {
            animationFrameRef.current = requestAnimationFrame(paint)
        }

        return () => {
            isMounted.current = false
        }
    }, [])

    return (
        <Page
            location={location}
            constrainedHeader
            constrainedFooter
            disableCta
        >
            <SEO
                location={location}
                title={"Blog"}
                description={
                    "Stay connected with 23shout, see our latest content, guides, tips, and articles."
                }
                ogImage={"https://23shout.com/social/og/og-blog.png"}
            />
            <Container className={"overlapPageMargins"}>
                <div className={"head overlapPageMargins"}>
                    <div className={"content pageConstrained"}>
                        <div className={"post top-post"}>
                            <div className={"left"}>
                                <div className={"sub"}>
                                    {
                                        data.recentArticle.nodes[0].frontmatter
                                            .subblog
                                    }
                                </div>
                                <h1>
                                    {
                                        data.recentArticle.nodes[0].frontmatter
                                            .title
                                    }
                                </h1>
                                <h2>
                                    {
                                        data.recentArticle.nodes[0].frontmatter
                                            .description
                                    }
                                </h2>
                                <div className={"meta-info"}>
                                    Published{" "}
                                    {
                                        data.recentArticle.nodes[0].frontmatter
                                            .date
                                    }{" "}
                                    by{" "}
                                    <GatsbyImage
                                        image={
                                            data.recentArticle.nodes[0]
                                                .frontmatter.avatar
                                                .childImageSharp.gatsbyImageData
                                        }
                                        alt={`${data.recentArticle.nodes[0].frontmatter.author}'s photo`}
                                        className={"avatar"}
                                    />
                                    {
                                        data.recentArticle.nodes[0].frontmatter
                                            .author
                                    }
                                </div>
                                <div>
                                    <Link
                                        to={`/${data.recentArticle.nodes[0].slug}`}
                                        tabIndex={-1}
                                    >
                                        <FlatButton
                                            color={"primary"}
                                            variant={"flat"}
                                            size={"larger"}
                                            fontWeight={600}
                                        >
                                            {
                                                data.recentArticle.nodes[0]
                                                    .timeToRead
                                            }{" "}
                                            min read <Chevron />
                                        </FlatButton>
                                    </Link>
                                </div>
                            </div>
                            <div className={"right"}>
                                <span
                                    ref={animContainerRef}
                                    className={"image-cont"}
                                >
                                    <GatsbyImage
                                        alt={
                                            data.recentArticle.nodes[0]
                                                .frontmatter.headerImageAlt
                                        }
                                        image={
                                            !!data.recentArticle.nodes[0]
                                                .frontmatter.cardImage
                                                ? data.recentArticle.nodes[0]
                                                      .frontmatter.cardImage
                                                      .childImageSharp
                                                      .gatsbyImageData
                                                : data.recentArticle.nodes[0]
                                                      .frontmatter.headerImage
                                                      .childImageSharp
                                                      .gatsbyImageData
                                        }
                                        className={"post-image"}
                                    />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"other-posts pageConstrained"}>
                    {data.recentArticle.nodes.length - 1 === 0 && (
                        <div className={"empty"}>
                            Our blog is brand new! Stay tuned, there'll be more
                            soon.
                        </div>
                    )}
                    {data.recentArticle.nodes.slice(1).map((p, i) => {
                        return (
                            <div className={"post"} key={`${p.id}`}>
                                <div className={"left"}>
                                    <div className={"sub"}>
                                        {p.frontmatter.subblog}
                                    </div>
                                    <h1>{p.frontmatter.title}</h1>
                                    <h2>{p.frontmatter.description}</h2>
                                    <Link to={`/${p.slug}`} tabIndex={-1}>
                                        <FlatButton
                                            fullWidth
                                            justify={"center"}
                                            color={"primary"}
                                            variant={"outlined"}
                                            size={"larger"}
                                            fontWeight={600}
                                        >
                                            {p.timeToRead} min read <Chevron />
                                        </FlatButton>
                                    </Link>
                                </div>
                                <div className={"right"}>
                                    <GatsbyImage
                                        alt={p.frontmatter.headerImageAlt}
                                        objectFit={"cover"}
                                        image={
                                            p.frontmatter.headerImage
                                                .childImageSharp.gatsbyImageData
                                        }
                                        className={"post-image"}
                                    />
                                </div>
                            </div>
                        )
                    })}
                </div>
            </Container>
        </Page>
    )
}

const Chevron = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="currentColor"
            className={"bottom-link-chevron"}
        >
            <path d="M9.29 6.71c-.39.39-.39 1.02 0 1.41L13.17 12l-3.88 3.88c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0l4.59-4.59c.39-.39.39-1.02 0-1.41L10.7 6.7c-.38-.38-1.02-.38-1.41.01z" />
        </svg>
    )
}

export const query = graphql`
    query blogQuery {
        recentArticle: allMdx(
            filter: { frontmatter: { type: { eq: "blog" } } }
            sort: { fields: [frontmatter___date], order: DESC }
            limit: 10
        ) {
            nodes {
                id
                timeToRead
                frontmatter {
                    title
                    description
                    date(formatString: "MMM D, YYYY")
                    subblog
                    author
                    avatar {
                        childImageSharp {
                            gatsbyImageData(
                                layout: FIXED
                                placeholder: NONE
                                width: 30
                                height: 30
                            )
                        }
                    }
                    headerImage {
                        childImageSharp {
                            gatsbyImageData(
                                quality: 100
                                layout: FIXED
                                width: 380
                                height: 480
                                transformOptions: {
                                    fit: COVER
                                    cropFocus: CENTER
                                }
                            )
                        }
                    }
                    cardImage {
                        childImageSharp {
                            gatsbyImageData(
                                quality: 100
                                layout: FIXED
                                width: 380
                                height: 480
                                transformOptions: {
                                    fit: COVER
                                    cropFocus: CENTER
                                }
                            )
                        }
                    }
                    headerImageAlt
                }
                timeToRead
                slug
            }
        }
    }
`

export default Blog
